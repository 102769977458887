/*-------------------------
    ChatGPT
-------------------------*/

.header-section {
    margin-top: 30px;
    text-align: center;
    h1 {
        font-size: 2.7rem;
        font-weight: 700;
    }
    
    p {
        font-size: 1rem;
        font-weight: 300;
        margin-top: 10px;
    }
}
  
.form-section {
    margin: 30px 0;
}

/* .answer-container {
overflow: scroll;
max-height: 500px;
} */

.answer-section {
    margin: 30px 0;
    position: relative;
    .question {
        background-color: #CA228C;
        padding: 20px;
        margin: 0;
    }
    .answer {
        background-color: #343536;
        padding: 20px;
    }
}
  
.copy-icon {
    position: absolute;
    background-color: #000000;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -10px;
    right: -10px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    &:hover {
      background-color: #CA228C;
    }
}


.chatgpt-area{
    textarea, button {
        width: 100%;
        border-radius: 5px;
        border: none;
    }
    
    .form-control {
        margin-bottom: 20px;
        padding: 20px;
        font-size: 1rem;
        font-family: 'Poppins',sans-serif;
        outline: none;
        background-color: #343536;
        color: #DADADB;
        transition: all 0.5s ease-in-out;
        &:focus {
            border: 2px solid gray;
            // border-top: 5px solid #CA228C;
            // border-top-left-radius: 0%;
            // border-bottom-left-radius: 0%;
            // border-top-right-radius: 0%;
        }
    }
    
    .btn {
        background: #000000;
        color: #DADADB;
        padding: 20px 0;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        &:hover {
            // border-left: 5px solid #CA228C;
            // border-top: 5px solid #CA228C;
            // border-top-left-radius: 0%;
            // border-bottom-left-radius: 0%;
            // border-top-right-radius: 0%;
            // box-shadow: 2px 2px 1px lightblue;
            border: 1px solid lightblue;
        }
    }
    
    .hr-line {
        margin: 70px 0 20px;
        border: 1px solid #343536;
    }
}
    
    
    